import $ from 'jquery';

let doropdownMenu = document.querySelector('.p-form__dropdown-menu');
let formChild = document.querySelector('.p-form__child');
let childInput = document.querySelector('#js-form-val-target');

document.addEventListener('click', (e) => {
  if(!e.target.closest('.p-form__child')) {
    doropdownMenu.classList.remove('is-open');
  }
});

formChild.addEventListener('click', () => {
  doropdownMenu.classList.add('is-open');
});
childInput.addEventListener('click', () => {
  doropdownMenu.classList.add('is-open');
});

$(function() {
	$('#js-form-val-target').click(function(e) {
		e.stopPropagation();
	});
	$('.js-doropdown-form-val').change(function(e){ 
		var cnt = 0;
		$('.js-doropdown-form-val').each(function(){
			cnt += parseInt( $(this).val() );
		});
		$('#js-form-val-target').val( cnt );
	});
});