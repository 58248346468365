const infoContent = document.querySelectorAll('.p-top-info__content');
const infoWrap = document.querySelector('.p-top-info__content-wrap');

if (infoContent.length >= 4 ) {
  if(window.matchMedia('(max-width:769px)').matches) {
    infoWrap.style.maxHeight = '352px';
    infoWrap.style.overflowY = 'scroll';
  } else {
    infoWrap.style.maxHeight = '460px';
    infoWrap.style.overflowY = 'scroll';
  }
}