import $ from 'jquery';
import 'slick-carousel';

const mvSlide = document.querySelector('.p-top-slider__item--video');

if(window.matchMedia('(max-width:769px)').matches) {
  mvSlide.remove();
}

const video = $('#js-video').get(0);
const mvHeading = document.querySelector('.p-top-mv-heading');
const mvSlider = $('.p-top-slider').slick({
  fade:true,
  autoplay: false,
  autoplaySpeed: 4900,
  speed: 1000,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  arrows: false,
  dots: true,
  pauseOnFocus: false,
  pauseOnHover: false,
  pauseOnDotsHover: false
});


$(window).on('load', function () {
  if (window.matchMedia('(min-width:769px)').matches) {
    mvSlider.on('afterChange', function(event, slick, currentSlide, nextSlide) {
      switch (currentSlide){
        case 3:
          video.play();
          break;
        default:
          video.pause();
          video.currentTime = 0;
          break;
      }
    });
  }
});

mvHeading.addEventListener('animationend', () => {
  mvSlider.slick('slickPlay')
});

const guestSlider = document.querySelector('.p-top-slider--service');
if(guestSlider != null){
  $('.p-top-slider--service').slick({
    autoplay: true,
    dots: true,
    slidesToShow: 4,
    preconstrow: '<button class="slide-arrow prev-arrow"></button>',
    nextArrow: '<button class="slide-arrow next-arrow"></button>',
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 660,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      }
    ]
  });
}
