const tabs = document.querySelectorAll('.p-form-tab__item');
const forms = [...document.querySelectorAll('form')];

for (let i = 0; i < tabs.length; i++) {
  tabs[i].addEventListener('click', tabToggle);
}

function tabToggle() {
  for (let i = 0; i < tabs.length; i++) {
    tabs[i].classList.remove('is-current');
  }
  for (let i = 0; i < forms.length; i++) {
    forms[i].classList.remove('is-current')
  }
  this.classList.add('is-current');
  const aryTabs = Array.prototype.slice.call(tabs);
  const index = aryTabs.indexOf(this);
  forms[index].classList.add('is-current');
}
