

const hamburgerButton = document.querySelector('.l-header__nav-menu-item');
      haederMenu = document.querySelector('.l-header__menu');

hamburgerButton.addEventListener('click', () => {
  hamburgerButton.classList.toggle('is-show');
  haederMenu.classList.toggle('is-show');
  document.querySelector('html').classList.toggle('u-hidden');
});

document.addEventListener('click', (e) => {
  if(!e.target.closest('.l-header__nav-menu-item')) {
    hamburgerButton.classList.remove('is-show');
    haederMenu.classList.remove('is-show');
    document.querySelector('html').classList.remove('u-hidden');
  }
});
