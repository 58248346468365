let maskOut = document.querySelectorAll('.js-mask');
let maskOutSubGallery = document.querySelectorAll('.js-sub-gallery-mask');

const options = {
  root: null, 
  rootMargin: "0px 0px",
  threshold: 0
};

const subOptions = {
  root: null, 
  rootMargin: "20% 0px",
  threshold: 0
};

const observer = new IntersectionObserver(IntersectFunction, options);
const observerSub = new IntersectionObserver(IntersectFunction, subOptions);

maskOut.forEach(item => {
  observer.observe(item);
});

maskOutSubGallery.forEach(item => {
  observerSub.observe(item);
});

function IntersectFunction(elements) {
  elements.forEach(element => {
    if (element.isIntersecting) {
      element.target.classList.add('is-mask-out');
    }
  });
}