let slideIn = document.querySelectorAll('.js-slide-in');

const options = {
  root: null, 
  rootMargin: "-10% 0px",
  threshold: 0
};

const observer = new IntersectionObserver(IntersectFunction, options);

slideIn.forEach(item => {
  observer.observe(item);
});

function IntersectFunction(elements) {
  elements.forEach(element => {
    if (element.isIntersecting) {
      element.target.classList.add('is-show');
    }
  });
}