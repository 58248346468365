import $ from 'jquery';
import datepickerFactory from 'jquery-datepicker';
import datepickerJAFactory from 'jquery-datepicker/i18n/jquery.ui.datepicker-ja';

datepickerFactory($);
datepickerJAFactory($);

$(function() {
  $.get("https://holidays-jp.github.io/api/v1/date.json", function(holidaysData) {
    $('#js-checkin').datepicker({
      buttonImageOnly: true,
      showOn: 'both',
      minDate: '+0d',
      onClose : function(date) {
        if ( date.length > 0 ) {
          $('input[name="ciDateY"]').val( (date.split("/")[0]) );
          $('input[name="ciDateM"]').val( (date.split("/")[1]) );
          $('input[name="ciDateD"]').val( (date.split("/")[2]) );
        }
        var dt = new Date(date);
        dt.setDate(dt.getDate() + 1);
        $('#js-checkout').datepicker('option', { minDate: dt }).datepicker( "refresh" );
      },
      beforeShowDay: function(date) {
        if (date.getDay() == 0) {
          return [true, 'day-sunday', null];
        } else if (date.getDay() == 6) {
          return [true, 'day-saturday', null];
        }
    
        var holidays = Object.keys(holidaysData);
        for (var i = 0; i < holidays.length; i++) {
          var holiday = new Date(Date.parse(holidays[i]));
          if (holiday.getYear() == date.getYear() &&
              holiday.getMonth() == date.getMonth() &&
              holiday.getDate() == date.getDate()) {
              return [true, 'day-holiday', null];
          }
        }
        return [true, 'day-weekday', null];
      }
    });

    $('#js-checkin').datepicker('setDate', 'today');

    $('#js-checkout').datepicker({
      buttonImageOnly: true,
      showOn: 'both',
      minDate: '+1d',
      onClose : function(date) {
        if ( date.length > 0 ) {
          $('input[name="coDateY"]').val( (date.split("/")[0]) );
          $('input[name="coDateM"]').val( (date.split("/")[1]) );
          $('input[name="coDateD"]').val( (date.split("/")[2]) );
        }
        var dt = new Date(date);
        dt.setDate(dt.getDate() - 1);
        $('#js-checkin').datepicker('option', { minDate: dt }).datepicker( "refresh" );
      },
      beforeShowDay: function(date) {
        if (date.getDay() == 0) {
          return [true, 'day-sunday', null];
        } else if (date.getDay() == 6) {
          return [true, 'day-saturday', null];
        }
    
        var holidays = Object.keys(holidaysData);
        for (var i = 0; i < holidays.length; i++) {
          var holiday = new Date(Date.parse(holidays[i]));
          if (holiday.getYear() == date.getYear() &&
              holiday.getMonth() == date.getMonth() &&
              holiday.getDate() == date.getDate()) {
              return [true, 'day-holiday', null];
          }
        }
        return [true, 'day-weekday', null];
      }
    });

    $('#js-checkout').datepicker('setDate', 'today');

    $('#js-datepicker--airline').datepicker({
      buttonImageOnly: true,
      showOn: 'both',
      minDate: '0',
      beforeShowDay: function(date) {
        if (date.getDay() == 0) {
          return [true, 'day-sunday', null];
        } else if (date.getDay() == 6) {
          return [true, 'day-saturday', null];
        }
    
        var holidays = Object.keys(holidaysData);
        for (var i = 0; i < holidays.length; i++) {
          var holiday = new Date(Date.parse(holidays[i]));
          if (holiday.getYear() == date.getYear() &&
              holiday.getMonth() == date.getMonth() &&
              holiday.getDate() == date.getDate()) {
              return [true, 'day-holiday', null];
          }
        }
        return [true, 'day-weekday', null];
      }
    });
    $('#js-datepicker--airline').datepicker('setDate', 'today');
    $.datepicker.regional['ja'];
  })
});